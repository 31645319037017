<template>
  <div class="page-body">
      <div class="container">
          <div class="content">
              <div class="logo">
                  <img src="@/assets/images/sec_logo.png" alt="logo" width="100">
                  <h1>Linkmeup</h1>
              </div>

              <div class="write-up">
                  <div>
                      <h1>Page not found</h1>
                  </div>
                  <div>
                      <p>The route you just entered does not exist, please click the button below to go back.</p>
                  </div>
                  <div>
                      <Button :style="{ color: '#fff', backgroundColor: '#159661', width: '100px' }" text="Go back" @click="$router.back()"/>
                  </div>
                  <div>
                      <router-link to="/">Go back home</router-link>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue'

export default {
    components: {
        Button
    }
}
</script>

<style scoped>
    .page-body {
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
    }

    .content {
        padding-top: 6rem;
    }

    .logo {
        text-align: center;
        color: #159661;
        padding-bottom: 5rem;
    }

    .write-up {
        text-align: center;
    }

    .write-up > div {
        margin-bottom: 15px;
    }

    .write-up p {
        font-size: 14px;
        color: #979797;
        width: 250px;
        margin: 0 auto
    }

    .write-up a {
        color: #000;
        text-decoration: none;
        font-size: 14px;
    }
</style>